<template>
    <div class="p-grid" v-if="mostrarListagem">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><i class="pi pi-list"></i> Acompanhamento de Projetos</h5>
                    <btn-refresh @click="atualizar()"></btn-refresh>
                </div>
                <div class="p-formgrid p-grid p-fluid">
                    <div class="p-field p-col-12 p-lg-6">
                        <label>Projeto </label>
                        <Dropdown
                            v-model="projeto"
                            :options="projetos"
                            optionLabel="nome"
                            :filter="true"
                            placeholder="Selecione o projeto"
                            :showClear="true"
                            :filterFields="['cnpj', 'nome']"
                            v-if="projetos"
                        >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{ $toCnpj(slotProps.value.cnpj) }} - {{ slotProps.value.nome }} - {{ slotProps.value.tipoDescr }}</div>
                                </div>
                                <span v-else>
                                    {{ slotProps.placeholder }}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{ $toCnpj(slotProps.option.cnpj) }} - {{ slotProps.option.nome }} - {{ slotProps.option.tipoDescr }}</div>
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                </div>
                <estatistica :dados="estatistica"></estatistica>
                <h5 class="heading-1 p-mb-4" v-if="projeto">
                    <span>Credores</span>
                </h5>
                <tabela
                    :data="credores"
                    dataKey="credorId"
                    :globalFilterFields="['nome', 'cpfCnpj']"
                    :filtersProp="filters"
                    :filterCallBack="dadosFiltrados"
                    stateKey="dt-credores"
                    v-if="projeto"
                    :small="true"
                >
                    <template #botoes>
                        <Button
                            type="button"
                            icon="pi pi-filter-slash"
                            label="Limpar Filtros"
                            class="p-button-outlined p-mr-2"
                            @click="clearFilter()"
                        />
                    </template>
                    <template #conteudo>
                        <Column field="nome" header="Nome/Razão Social" :sortable="true">
                            <template #body="{ data }">
                                {{ data.nome }}
                            </template>
                            <template #filter="{ filterModel }">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo nome" />
                            </template>
                        </Column>
                        <Column field="classe" header="Classe" :sortable="true">
                            <template #body="{ data }">
                                {{ data.classe }}
                            </template>
                            <template #filter="{ filterModel }">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela classe" />
                            </template>
                        </Column>
                        <Column
                            field="statusPropostaDescr"
                            header="Voto"
                            :sortable="true"
                            filterField="statusPropostaId"
                            :showFilterMatchModes="false"
                        >
                            <template #filter="{ filterModel }">
                                <MultiSelect
                                    v-model="filterModel.value"
                                    :options="statusPropostas"
                                    optionLabel="descricao"
                                    optionValue="statusPropostaId"
                                    placeholder="Qualquer"
                                    class="p-column-filter"
                                >
                                </MultiSelect>
                            </template>
                        </Column>
                        <Column
                            field="statusProcuracaoDescr"
                            header="Qualidade"
                            :sortable="true"
                            filterField="statusProcuracaoId"
                            :showFilterMatchModes="false"
                            v-if="isRecuperacaoJudicial"
                        >
                            <template #filter="{ filterModel }">
                                <MultiSelect
                                    v-model="filterModel.value"
                                    :options="statusProcuracoes"
                                    optionLabel="descricao"
                                    optionValue="statusProcuracaoId"
                                    placeholder="Qualquer"
                                    class="p-column-filter"
                                >
                                </MultiSelect>
                            </template>
                        </Column>
                        <Column field="valorQGC" header="Valor QGC" dataType="numeric" :sortable="true" v-if="isRecuperacaoJudicial">
                            <template #body="{ data }">
                                <div class="p-text-nowrap p-text-right">
                                    <span>{{ $numeral(data.valorQGC).format('$0,0.00') }}</span>
                                </div>
                            </template>
                            <template #filter="{ filterModel }">
                                <InputNumber v-model="filterModel.value" class="p-column-filter" mode="currency" currency="BRL" locale="pt-BR" />
                            </template>
                        </Column>
                        <Column field="responsavel" header="Representante" :sortable="true">
                            <template #body="{ data }">
                                {{ data.responsavel }}
                            </template>
                            <template #filter="{ filterModel }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    class="p-column-filter"
                                    placeholder="Pesquisar pelo representante"
                                />
                            </template>
                        </Column>
                        <Column
                            field="statusContatoDescr"
                            header="Contato"
                            :sortable="true"
                            filterField="statusContatoId"
                            :showFilterMatchModes="false"
                        >
                            <template #filter="{ filterModel }">
                                <MultiSelect
                                    v-model="filterModel.value"
                                    :options="statusContatos"
                                    optionLabel="descricao"
                                    optionValue="statusContatoId"
                                    placeholder="Qualquer"
                                    class="p-column-filter"
                                >
                                </MultiSelect>
                            </template>
                        </Column>
                    </template>
                </tabela>
            </div>
        </div>
    </div>
</template>

<script>
import AcompanhamentosServices from './services';
import Estatistica from './Estatistica';

import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { estados2 } from '../../shared/components/estados';
import { tiposProjetosEnum } from '../projetos/tiposProjetos';

export default {
    components: {
        Estatistica,
    },

    data() {
        return {
            projetos: null,
            credores: null,
            projeto: null,
            filters: null,
            estados: estados2,
            naturezas: [],
            situacoesCadastrais: [],
            statusCessoes: [],
            statusContatos: [],
            statusNegociacoes: [],
            statusPropostas: [],
            statusProcuracoes: [],
            statusInteresses: [],
            origens: [],
            credoresFiltrados: [],
            estatistica: null,
        };
    },

    created() {
        // this.initFilters();
    },

    methods: {
        obterOpcoes() {
            this.$store.dispatch('addRequest');
            AcompanhamentosServices.obterOpcoesFormulario(this.tipoProjeto).then((response) => {
                if (response && response.success) {
                    this.naturezas = response.data.naturezas;
                    this.situacoesCadastrais = response.data.situacoesCadastrais;
                    this.statusCessoes = response.data.statusCessoes;
                    this.statusContatos = response.data.statusContatos;
                    this.statusNegociacoes = response.data.statusNegociacoes;
                    this.statusPropostas = response.data.statusPropostas;
                    this.statusProcuracoes = response.data.statusProcuracoes;
                    this.origens = response.data.origens;
                    this.statusInteresses = response.data.statusInteresses;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterProjetos() {
            this.$store.dispatch('addRequest');
            AcompanhamentosServices.obterProjetos(tiposProjetosEnum.RECUPERACAOJUDICIAL).then((response) => {
                if (response && response.success) {
                    this.projetos = response.data;
                } else {
                    this.projetos = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterCredores() {
            this.$store.dispatch('addRequest');
            AcompanhamentosServices.obterCredores(this.projeto.projetoId).then((response) => {
                if (response && response.success) {
                    this.credores = response.data.credores;
                    this.estatistica = response.data.estatistica;
                } else {
                    this.credores = null;
                    this.estatistica = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterCredores();
        },

        clearFilter() {
            this.filters = null;
            this.initFilters();
        },

        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                nome: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                proximoContato: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                cpfCnpj: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                valorNominal: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
                valorCorrigido: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
                precoTarget: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
                precoMaximo: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
                valorQGC: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
                valorPlano: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
                uf: { value: null, matchMode: FilterMatchMode.IN },
                statusContatoId: { value: null, matchMode: FilterMatchMode.IN },
                statusInteresseId: { value: null, matchMode: FilterMatchMode.IN },
                statusNegociacaoId: { value: null, matchMode: FilterMatchMode.IN },
                statusPropostaId: { value: null, matchMode: FilterMatchMode.IN },
                statusCessaoId: { value: null, matchMode: FilterMatchMode.IN },
                statusProcuracaoId: { value: null, matchMode: FilterMatchMode.IN },
            };
        },

        dadosFiltrados(filterData) {
            this.credoresFiltrados = [...filterData.filteredValue];
        },
    },

    mounted() {
        this.obterOpcoes();
        this.obterProjetos();
        this.initFilters();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Acompanhamentos';
        },

        isCessaoCredito() {
            return this.projeto && this.projeto.tipo == tiposProjetosEnum.CESSAOCREDITO;
        },

        isRecuperacaoJudicial() {
            return this.projeto && this.projeto.tipo == tiposProjetosEnum.RECUPERACAOJUDICIAL;
        },

        tipoProjeto() {
            return this.isCessaoCredito ? 'cc' : 'rj';
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Credores') {
                if (this.$store.getters.atualizar) {
                    this.obterCredores();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },

        projeto() {
            if (this.projeto) {
                this.obterCredores();
            } else {
                this.credores = null;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.p-dropdown {
    width: 100%;
}

::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}

::v-deep(.p-datepicker) {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}

::v-deep(.p-datatable.p-datatable-customers) {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: left;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
        text-transform: uppercase;
    }
}
</style>
