<template>
    <h5 class="heading-1 p-mb-4" v-if="dados">
        <span>Resumo</span>
    </h5>
    <tabela
        :data="dados"
        dataKey="item"
        :globalFilterFields="['item']"
        stateKey="dt-acompanhamento"
        v-if="dados"
        :small="true"
        :mostrarPaginacao="false"
        :mostrarHeader="false"
    >
        <template #conteudo>
            <Column field="item" header="Voto">
                <template #body="{ data }">
                    {{ data.item }}
                </template>
            </Column>
            <Column field="quantidade" header="Quantidade">
                <template #body="{ data }">
                    <div class="p-text-nowrap p-text-right">
                        {{ data.quantidade }}
                    </div>
                </template>
            </Column>
            <Column field="percentualQuantidade" header="% Quantidade">
                <template #body="{ data }">
                    <div class="p-text-nowrap p-text-right">
                        {{ $numeral(data.percentualQuantidade).format('0,0.00%') }}
                    </div>
                </template>
            </Column>
            <Column field="valor" header="Valor">
                <template #body="{ data }">
                    <div class="p-text-nowrap p-text-right">
                        {{ $numeral(data.valor).format('$0,0.00') }}
                    </div>
                </template>
            </Column>
            <Column field="percentualValor" header="% Valor">
                <template #body="{ data }">
                    <div class="p-text-nowrap p-text-right">
                        {{ $numeral(data.percentualValor).format('0,0.00%') }}
                    </div>
                </template>
            </Column>
        </template>
    </tabela>
</template>

<script>
export default {
    props: {
        dados: {
            type: Array,
        },
    },
};
</script>