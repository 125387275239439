import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PROJETOS_PORT}${process.env.VUE_APP_API_PATH}`;
const urlBase = `${api}/acompanhamentos`;

export default {
    obterProjetos(tipoProjeto) {
        return axiosJwt.get(`${urlBase}/projetos/tipo/${tipoProjeto}`);
    },

    obterOpcoesFormulario(tipoProjeto) {
        return axiosJwt.get(`${urlBase}/opcoesformulario/${tipoProjeto}`);
    },

    obterCredores(projetoId) {
        return axiosJwt.get(`${urlBase}/projetos/${projetoId}`);
    },

    // obterProjetoPorId(projetoId) {
    //     return axiosJwt.get(`${urlBase}/projetos/${projetoId}/detalhar`);
    // },

    // obterCredorPorId(id) {
    //     return axiosJwt.get(`${urlBase}/${id}`);
    // },

    // inserirCredor(credor) {
    //     return axiosJwt.post(`${urlBase}/inserir`, credor);
    // },

    // atualizarCredor(credor) {
    //     return axiosJwt.post(`${urlBase}/atualizar`, credor);
    // },

    // exportarCredores(credoresId) {
    //     return axiosJwt.post(`${urlBase}/exportar`, credoresId, {
    //         responseType: 'blob',
    //     });
    // },

    // emitirContrato(credorId) {
    //     return axiosJwt.get(`${urlBase}/${credorId}/emitircontrato`, {
    //         responseType: 'blob',
    //     });
    // },
};
